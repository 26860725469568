import React from "react";

import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

import { Link, useIntl } from "gatsby-plugin-react-intl";

const ArticleTile = ({ link, title, description, gatsbyImage, staticImage }) => {
  const intl = useIntl();

  return (
    <article className="group article">
      <Link className="h-full" to={`/${link}`}>
        <div className="space-y-4">
          <div className="aspect-[3/2] w-full overflow-hidden bg-white group relative">
             {gatsbyImage && (
              <GatsbyImage
                image={getImage(gatsbyImage)}
                alt=""
                className="w-full h-full"
                objectFit="cover"
              />
            )}
               <span class="bg-black bg-opacity-35 transition-all inset-0 absolute group-hover:opacity-100 opacity-0 grid place-content-center backdrop-blur-md">
                              
                             
                              <span className="inline-block px-4 py-2 font-medium text-black rounded-lg bg-utdf-secondary">
                                {intl.formatMessage({ id: "More" })} &rarr;
                              </span>{" "}
                            </span>
          </div>
          <div className="w-full space-y-4">
            <h2 className="text-2xl group-hover:underline ">{title}</h2>
      
      
          </div>
        </div>
      </Link>
    </article>
  );
};

export default ArticleTile;
