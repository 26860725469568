import * as React from "react";
import Layout from "../components/layout";
import { Link, useIntl } from "gatsby-plugin-react-intl";
import { StaticQuery, graphql } from "gatsby";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Image from "../components/image";
import ArticleTile from "../components/article-tile";

const ProjectsPage = () => {
  const intl = useIntl();
  return (
    <>
      <StaticQuery
        query={graphql`
          query About {
            allWpPage {
              edges {
                node {
                  title
                  slug
                  content
                  page {
                    pageGroup
                    language
                    shortDescription
                  }
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const pages = data.allWpPage.edges;
          const projects = pages.filter(
            (i) =>
              i.node.page.pageGroup === "about" &&
              i.node.page.language === intl.locale
          );

          return (
            <Layout>
              <main className="pb-10 overflow-hidden">
                {/* <div className="absolute z-[-2]  h-[100vh] top-[-88px] left-[-20px]  w-[calc(100%+20px)]">
                  <Image
                    src="kapusta.png"
                    className="w-full h-full"
                    objectFit="cover"
                    objectPosition="bottom center"
                    alt=""
                  />
                </div>
                <div className="absolute z-[-1] top-[calc(100vh-248px)] w-[calc(100%+20px)] h-40 from-transparent to-utdf bg-gradient-to-b left-[-20px] " /> */}

                <header className="px-4 py-10 sm:pt-[7.5rem]">
                  <h1 className="big-heading">
                    {intl.formatMessage({ id: "AboutFestival" })}
                  </h1>
                </header>
                <div className="grid items-start grid-cols-1 gap-8 px-8 mx-auto md:grid-cols-2 lg:grid-cols-3 max-w-7xl lg:py-8">
                  {projects.map((project, i) => (
                    <ArticleTile
                      key={i}
                      link={project.node.slug}
                      title={project.node.title}
                      gatsbyImage={getImage(
                        project.node?.featuredImage.node.localFile
                      )}
                      description={project.node.page.shortDescription}
                    />
                  ))}
                </div>
              </main>
            </Layout>
          );
        }}
      ></StaticQuery>
    </>
  );
};

export default ProjectsPage;
